import styled from "styled-components"

export const SearchWrapper = styled.section`
  display: flex;  
  flex-direction: column;
  width: 65%;
  background: var(--background);
  transition: opacity 0.4s;
  align-items: flex-start;
  justify-content: center;
  margin: 0 auto;
  .ais-InstantSearch__root {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 100%;
  }

  .ais-SearchBox,
  .ais-Stats {
    padding: 0.5rem 3rem;
  }

  .ais-SearchBox {
    padding-top: 2rem;
    width: 100%;
  }

  .ais-Stats {
    color: var(--texts);
  }

  .ais-SearchBox-input {
    display: flex;
    padding: 0.5rem;
    width: 100%;
    background: none;
    border: none;
    border-bottom: 1px solid var(--borders);
    font-size: 1.6rem;
    color: var(--texts);
    &::placeholder {
      color: var(--texts);
    }
  }
  
  .ais-SearchBox-submit,
  .ais-SearchBox-reset {
    display: none;
  }
`
